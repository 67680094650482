import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {configuration, toJsonIfOkResponse} from "../../Util";
import {Competition, CompetitionState, FlatCompetitionPlayer} from "../../models/competitionState";
import {getToken} from "../../app/keycloak";

export const createCompetition = createAsyncThunk<any, string, any>(
    'competition/create',
    async name => {
        const token = await getToken();
        return await fetch(`${configuration.baseUrl}/competition/create`,{
            method: "POST",
            headers: {'Authorization':'Bearer ' + token, 'Content-Type':'application/x-www-form-urlencoded'},
            body: `name=${name}`
        })
            .then(toJsonIfOkResponse)
    }
);

export const resetPresence = createAsyncThunk<any, string, any>(
    'competition/resetPresence',
    async competitionToken => {
        const token = await getToken();
        return await fetch(`${configuration.baseUrl}/v2/player/resetPresence`,{
            method: "POST",
            headers: {'Authorization':'Bearer ' + token, 'Content-Type':'application/x-www-form-urlencoded'},
            body: `competitionToken=${competitionToken}`
        }).then(resp => resp.ok)
    }
);
export const addPlayer = createAsyncThunk<any, any[], any>(
    'competition/addPlayer',
    async playerDetails => {
        const token = await getToken();
        return await fetch(`${configuration.baseUrl}/v2/player/createPlayer`,{
            method: "POST",
            headers: {'Authorization':'Bearer ' + token, 'Content-Type':'application/x-www-form-urlencoded'},
            body: `competitionToken=${playerDetails[0]}&name=${playerDetails[1]}&type=${playerDetails[2]}&startRating=${playerDetails[3]}`
        })
            .then(toJsonIfOkResponse)
    }
);

export const updatePlayer = createAsyncThunk<any, any[], any>(
    'competition/updatePlayer',
    async playerDetails => {
        const token = await getToken();
        return await fetch(`${configuration.baseUrl}/v2/player/updatePlayer`,{
            method: "POST",
            headers: {'Authorization':'Bearer ' + token, 'Content-Type':'application/x-www-form-urlencoded'},
            body: `playerId=${playerDetails[0]}&type=${playerDetails[1]}`
        })
            .then(toJsonIfOkResponse)
    }
);

export const getCompetitionPlayers = createAsyncThunk<any, undefined, any>(
    'competition/competitionPlayers',
    async _ => {
        const token = await getToken();
        return await fetch(`${configuration.baseUrl}/competition/competitionPlayers`, {
            method: "GET",
            headers: {'Authorization':'Bearer ' + token},
            credentials: "include"
        })
            .then(toJsonIfOkResponse)
    }
);

const initialState: CompetitionState = {
    competitions: []
};

export const competitionSlice = createSlice({
        name: 'competition',
        initialState: initialState,
        reducers: {},
        extraReducers: (builder) => {
            builder.addCase(createCompetition.fulfilled, (state: CompetitionState, action: { payload: FlatCompetitionPlayer[]; }) => {
                updateState(state, action.payload);
            }).addCase(getCompetitionPlayers.fulfilled, (state: CompetitionState, action: {payload: FlatCompetitionPlayer[]}) => {
                updateState(state, action.payload);
            }).addCase(addPlayer.fulfilled, (state: CompetitionState, action: {payload: FlatCompetitionPlayer[]}) => {
                updateState(state, action.payload);
            }).addCase(updatePlayer.fulfilled, (state: CompetitionState, action: {payload: FlatCompetitionPlayer[]}) => {
                updateState(state, action.payload);
            })
        }
    }
)

function updateState(state: CompetitionState, flatPlayers: FlatCompetitionPlayer[]){
    const comps: Competition[] = [];
    flatPlayers.forEach(flatPlayer => {
        const compToken = flatPlayer.competitionToken;
        let competition = comps.find(comp => comp.competitionToken === compToken);
        if (competition === undefined){
            competition = {competitionToken: compToken, competitionName: flatPlayer.competitionName, players: []};
            comps.push(competition);
        }
        if (
            flatPlayer.playerId
            && flatPlayer.playerName
            && flatPlayer.playerType
            && flatPlayer.playerRating
        ){
            competition?.players.push({
                playerId: flatPlayer.playerId,
                playerName: flatPlayer.playerName,
                playerType: flatPlayer.playerType,
                playerRating: flatPlayer.playerRating
            });
        }
    })
    comps.forEach(comp => comp.players = comp.players
        .sort((p1, p2) => (p1.playerName || "").toLowerCase().localeCompare((p2.playerName || "").toLowerCase()))
        .sort((p1, p2) => p2.playerType.localeCompare(p1.playerType)));
    state.competitions = comps;
}

export const getCompetitions = (state: {competition: CompetitionState}) => state.competition.competitions
