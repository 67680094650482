import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {configuration, getCompetitionToken, toJsonIfOkResponse} from "../../Util";
import {Rating} from "../../models/rating";

export const fetchRating = createAsyncThunk<any, any, any>(
    'rating',
    async _ => {
        return await fetch(`${configuration.baseUrl}/rating?competitionToken=${getCompetitionToken()}`, {
            method: "GET"
        }).then(toJsonIfOkResponse)
    }
);

const initialState: Rating = {
    gameRatings: [],
    playerRatings: []
}

export const ratingSlice = createSlice({
        name: 'rating',
        initialState: initialState,
        reducers: {},
        extraReducers: (builder) => {
            builder.addCase(fetchRating.fulfilled, (state: Rating, action: { payload: Rating; }) => {
                state.gameRatings = action.payload.gameRatings;
                state.playerRatings = action.payload.playerRatings;
            })
        }
    }
)

export const gameRatings = (state: {rating: Rating}) => state.rating.gameRatings;
export const playerRatings = (state: {rating: Rating}) => state.rating.playerRatings;
