// keycloak.js
import Keycloak from 'keycloak-js';
import {setAuthenticated} from "../features/auth/authSlice";
import {store} from "./store";
import {configuration, getCompetitionToken} from "../Util";

const keycloakConfig = {
    url: 'https://auth.easingyou.com/auth',
    realm: 'EasingYou',
    clientId: configuration.keycloakClientId
};

const keycloak = new Keycloak(keycloakConfig);

// Initialize Keycloak
if (!getCompetitionToken()){
    keycloak.init({ onLoad: 'check-sso' }).then((authenticated) => {
        if (authenticated) {
            dispatchAuthentication();
        }
    });
}


// Event listener for successful authentication
keycloak.onAuthSuccess = () => {
    dispatchAuthentication();
};

// Event listener for logout
keycloak.onAuthLogout = () => {
    dispatchAuthentication();
};

function dispatchAuthentication(){
    store.dispatch(setAuthenticated({
        isAuthenticated: keycloak.authenticated
    }));

}

export function getToken() {
    return keycloak.updateToken(5)
        .then(refreshed => {
            // Token is valid (either was already valid or has been refreshed)
            return keycloak.token;
        })
        .catch(error => {
            console.error("Failed to refresh token", error);
            keycloak.login();
        });
}


// Add other event listeners as needed (e.g., onTokenExpired)

export default keycloak;
