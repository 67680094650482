import {playerPresence} from "./playerPresenceSlice";
import {useAppSelector} from "../../app/hooks";
import {PlayerType} from "../../models/players";
import PlayerTable from "./PlayerTable";
import {Accordion, AccordionDetails, AccordionSummary, Alert, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from "react";

function PlayerPresence() {
    const players = useAppSelector(playerPresence);
    const members = players.filter(player => player.type === PlayerType.Member);
    const guests = players.filter(player => player.type === PlayerType.Guest);

    const membersOn = members.filter(member => member.present === true).length;
    const guestsOn = guests.filter(guest => guest.present === true).length;
    const noGuestInfo = guests.length === 0 ? <p>Er zijn nog geen invallers. De competitieleider kan deze aanmaken.</p> : null;

    return <div>
        <PlayerTable players={members}/>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography style={{fontWeight: 'bold'}}>Invallers</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Alert icon={false} severity={"info"}>
                    {noGuestInfo}
                    <p>Invallers hebben een rating, maar doen niet mee in de klassementen.</p>
                </Alert>
                <PlayerTable players={guests}/>
            </AccordionDetails>
        </Accordion>

        <p><strong>Aan (leden / invallers): {membersOn + guestsOn} ({membersOn} / {guestsOn})</strong></p>
    </div>;
}

export default PlayerPresence;
