import {Button, Chip, Grid} from "@mui/material";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {postResult} from "./gameSlice";
import {Game} from "../../models/game";
import {ranking} from "../rank/rankSlice";

function ScoreButton({game, isHome}: {game: Game, isHome: boolean}){
    const dispatch = useAppDispatch();
    const month = useAppSelector(ranking).month;
    const players = isHome ? game.homePlayers : game.awayPlayers;
    const points = isHome ? game.homePoints : game.awayPoints;

    let color: 'error' | 'info' | 'success';
    if (game.homePoints === null || game.awayPoints === null || game.homePoints === game.awayPoints){
        color = "info";
    } else if (game.homePoints > game.awayPoints && isHome) {
        color = "success";
    } else if (game.homePoints < game.awayPoints && !isHome) {
        color = "success";
    } else {
        color = "error";
    }
    const chip = points === null ? null : <Chip label={points} color={color} variant="filled" onClick={(event) => {dispatch(postResult([game.id, month, isHome, -1]));event.stopPropagation();}}/>;

    return <Button variant={"outlined"}
                   style={{paddingRight: 10, paddingLeft: 10}}
                           color={color}
                           onClick={() => dispatch(postResult([game.id, month, isHome, 1]))}>
        <Grid container spacing={0} alignItems={"center"}>
            <Grid item xs={9}>
                {players.join(' / ')}
            </Grid>
            <Grid item xs={3}>
                {chip}
            </Grid>
        </Grid>
       </Button>

}


export default ScoreButton;
