import {Alert, Button, Grid, Paper, TableContainer} from "@mui/material";
import React, {useEffect, useRef} from "react";
import Trophy from "../rank/trophy";
import {PlayerRatingRow} from "../../models/rating";
import {fetchRating, playerRatings} from "./ratingSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import RatingDetailsSection from "./RatingDetailsSection";
import RatingDiffCell from "./RateDiffCell";
import RatingExplanation from "../general/ratingExplanation";

function RatingSection(){
    const detailsRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(fetchRating([]));
    }, [dispatch]);
    const rows = useAppSelector(playerRatings);
    const scrollToDetails = () => {
        if (detailsRef.current) {
            detailsRef.current.scrollIntoView();
        }
    };
    const [selectedPlayer, setSelectedPlayer] = React.useState<PlayerRatingRow|undefined>(undefined);

    const rowGrid = rows.length === 0 ? null :
        <TableContainer component={Paper}  style={{marginTop: '0.5em'}}>
            <Grid container>
            {rows.slice().sort(compareRows).map((row, index) => (
                        <Button style={{textAlign: "left", borderBottom: "groove"}} key={row.playerId} fullWidth onClick={() => {setSelectedPlayer(row);scrollToDetails()}}>
                            <Grid container>
                                <Grid item xs={1}>
                                    {index + 1}
                                </Grid>
                                <Grid item xs={7}>
                                    {row.playerName} <Trophy name={row.playerName}/>
                                </Grid>
                                <Grid item xs={2}>
                                    {row.currentRating}
                                </Grid>
                                <Grid item xs={2}>
                                    <RatingDiffCell lastChange={row.lastChange}/>
                                </Grid>
                            </Grid>
                        </Button>
                ))}
            </Grid>
        </TableContainer>

    return <div style={{marginTop: '0.5em'}}>
        <Alert severity="info">Klik op een regel voor de totstandkoming.</Alert>
        {rowGrid}
        <div ref={detailsRef}>
            <RatingDetailsSection playerRow={selectedPlayer}/>
        </div>
        <h2>Hoe werkt de rating?</h2>
        <RatingExplanation/>

        <h2>Is rating anders dan ranglijst aller tijden?</h2>
        <p>
            Ja. Het systeem is er op gericht wedstrijden te genereren waarbij koppels zoveel mogelijk dezelfde
            kansen hebben. De beste speler heeft daarmee niet meer kans
            om een klassement te winnen dan de minste speler.
        </p>
        <p>
            Als de beheerder van de competitie ervoor kiest om iedereen met dezelfde rating te laten beginnen,
            hebben de betere spelers de eerste wedstrijden voordeel in het klassement.
            Dat voordeel is na een aantal wedstrijden weg, waardoor de volgorde in de maandklassementen niet meer
            samenhangt met de rating. Het aandeel van de eerste wedstrijden in de ranglijst aller tijden
            wordt met elke gespeelde wedstrijd iets kleiner, waardoor ook dit klassement nivelleert en steeds minder
            zegt over de speelsterkte.
        </p>
        <p>
            De rating is anders dan een klassement:
        </p>
        <ul>
            <li>rating is een weergave van de speelsterkte van de atleten, klassement het resultaat van wedstrijden
                met gelijke kansen
            </li>
            <li>niet spelen is slecht voor je klassement, maar rating verandert niet</li>
            <li>rating houdt rekening met de sterkte van je medespeler t.o.v. de sterkte van je tegenstanders, het
                klassement niet
            </li>
        </ul>
    </div>
}

function compareRows(r1: PlayerRatingRow, r2: PlayerRatingRow): number{
    if (r1.currentRating === r2.currentRating){
        return r1.playerName.localeCompare(r2.playerName);
    }
    return r1.currentRating < r2.currentRating ? 1 : -1;
}
export default RatingSection;
