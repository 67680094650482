export enum PossibleConnectionState{
    PENDING = 'PENDING',
    FULFILLED = "FULFILLED",
    REJECTED = "REJECTED"
}

export interface ConnectionState{
    state?: PossibleConnectionState,
    message?: string
}

