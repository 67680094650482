import {Configuration} from "./models/conf";

export const configuration: Configuration = process.env.REACT_APP_STAGE === "PROD"
    ?
    {
        baseUrl: "https://toss.easingyou.com/api",
        keycloakClientId: 'toss'
    }
    :
    {
        baseUrl: "http://macbook-pro.local:8088/api",
        keycloakClientId: 'tossDev'
    };

export function getCompetitionToken(){
    return new URL(window.location.href).searchParams.get("token");
}

export function toJsonIfOkResponse(resp: Response) {
    if (!resp.ok){
        throw Error('code ' + resp.status);
    }
    return resp.json();
}

export function toTextIfOkResponse(resp: Response) {
    if (!resp.ok){
        throw Error('code ' + resp.status);
    }
    return resp.text();
}
export function throwIfNotOk(resp: Response) {
    if (!resp.ok){
        throw Error('code ' + resp.status);
    }
    return resp;
}
