import {useAppSelector} from "../../app/hooks";
import {gameRatings} from "./ratingSlice";
import {Alert, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip} from "@mui/material";
import React, {CSSProperties} from "react";
import {GameRatingRow, GameResult, PlayerRatingRow} from "../../models/rating";

function RatingDetailsSection(props :{playerRow: PlayerRatingRow | undefined}){
    const rows = useAppSelector(gameRatings);
    const playerRow = props.playerRow
    if (playerRow === undefined){
        return null;
    }

    const playerRows = rows.filter(row => row.playerId === playerRow.playerId);
    if (playerRows.length === 0){
        return <Alert severity="info">{playerRow.currentRating} is de initiële rating van {playerRow.playerName}. Er zijn nog geen wedstrijden gespeeld door {playerRow.playerName}.</Alert>
    }

    const borderBottomDotted = {borderBottom: '1px dotted black'};
    const cellPaddingRight = {paddingRight: '0.5em'};
    const cellPaddingLeftRight = {paddingRight: '0.5em', paddingLeft: '1em'};
    return <div>
        <h3 style={{marginBottom: 0}}>Totstandkoming rating van {playerRows[0].playerName}</h3>
        <TableContainer component={Paper} style={{marginTop: '0.5em'}}>

        <Table padding={"none"} size="small">
            <TableHead>
                <TableRow>
                    <TableCell>
                    </TableCell>
                    <TableCell style={cellPaddingRight}>
                        <Tooltip style={borderBottomDotted} enterTouchDelay={0}
                                 title={"Rating na de gespeelde wedstrijd."}>
                            <span>Rt.</span>
                        </Tooltip>
                    </TableCell>
                    <TableCell style={cellPaddingRight}>
                        Thuis
                    </TableCell>
                    <TableCell style={cellPaddingRight}>
                        Uit
                    </TableCell>
                    <TableCell style={cellPaddingRight}>
                        <Tooltip style={borderBottomDotted} leaveTouchDelay={6500} enterTouchDelay={0}
                                 title={`Het verwachte deel van de punten dat ${playerRow.playerName} deze wedstrijd zou halen. 1 betekent alle punten. Bij even sterke teams is dit 0.5`}>
                            <span>Verw.</span>
                        </Tooltip>
                    </TableCell>
                    <TableCell style={cellPaddingRight}>
                        <Tooltip style={borderBottomDotted} leaveTouchDelay={6500} enterTouchDelay={0}
                                 title={`Het deel van de punten dat ${playerRow.playerName} gehaald heeft.`}>
                            <span>Scr.</span>
                        </Tooltip>
                    </TableCell>
                    <TableCell style={cellPaddingLeftRight}>
                        <Tooltip style={borderBottomDotted} leaveTouchDelay={6500} enterTouchDelay={0}
                                 title={"De verandering van de elo-rating door deze wedstrijd. "}>
                            <span>+/-</span>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {playerRows.map((ratingRow, rowIndex) => {
                    const gameRows = rows.filter(row => ratingRow.gameId === row.gameId);
                    return <TableRow
                        key={ratingRow.gameId}
                        style={rowIndex % 2 === 1 ? {backgroundColor: "#ebebff"} : {}}
                    >
                        <TableCell style={cellPaddingRight}>{rowIndex + 1}</TableCell>
                        <TableCell style={cellPaddingRight}>{ratingRow.newRating}</TableCell>
                        <TableCell style={cellPaddingRight}>{getGameRow(gameRows, ratingRow, true)}</TableCell>
                        <TableCell style={cellPaddingRight}>{getGameRow(gameRows, ratingRow, false)}</TableCell>
                        <TableCell style={cellPaddingRight}>{ratingRow.expectedScore}</TableCell>
                        <TableCell style={cellPaddingRight}>{ratingRow.score}</TableCell>
                        <TableCell>{getChangedScoreRow(ratingRow)}</TableCell>
                    </TableRow>;
                })}
            </TableBody>
        </Table>
    </TableContainer>
    </div>
}

function getGameRow(gameRows: GameRatingRow[], playerRow: GameRatingRow, home: boolean){
    let rows = gameRows.filter(row => row.homePlayer === home);
    const nameStyle = {marginTop: 0, marginBottom: 0};
    let divStyle: CSSProperties = getCellMargin();
    if (playerRow.result && playerRow.result !== GameResult.DRAW){
        const clr = playerRow.homePlayer === (playerRow.result === GameResult.HOME_WIN)? "green" : "red";
        divStyle = {color: clr, ...divStyle};
    }
    return <div style={divStyle}>
        {rows.map(row => <p key={row.playerId} style={row.playerId === playerRow.playerId ? {fontWeight: "bold", ...nameStyle}: nameStyle}>{row.playerName}</p>)}
    </div>
}

function getChangedScoreRow(playerRow: GameRatingRow){
    if (playerRow.earnedRating === null || playerRow.score === null){
        return <p></p>
    }

    const noVertMargin = {marginTop: 0, marginBottom: 0};

    let resultExplanation;
    if (playerRow.expectedScore === playerRow.score){
        resultExplanation = "precies aan de verwachtingen voldaan";
    } else {
        let belowAbove, incrDecr;
        if (playerRow.score < playerRow.expectedScore){
            belowAbove = "beneden";
            incrDecr = "verlaagd";
        } else {
            belowAbove = "boven";
            incrDecr = "verhoogd";
        }
        resultExplanation = `${belowAbove} verwachting gepresteerd. De rating van ${playerRow.playerName} wordt daarom volgens het Elo systeem met ${Math.abs(playerRow.earnedRating)} ${incrDecr}.`;
    }
    const increasedOrDecreased = `De verwachte score van ${playerRow.playerName} was ${playerRow.expectedScore}. Met de werkelijke score van ${playerRow.score} is ${resultExplanation}`;

    return <Tooltip style={{borderBottom: '1px dotted black', textAlign: "center", ...getCellMargin()}} leaveTouchDelay={15000} enterTouchDelay={0}
             title={increasedOrDecreased}>
        <div>
        <p style={noVertMargin}>{playerRow.earnedRating}</p>
    </div>
    </Tooltip>
}

function getCellMargin(): CSSProperties{
    return {marginTop:10, marginBottom: 10};
}

export default RatingDetailsSection;
