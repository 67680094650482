import {Player} from "../../models/players";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {configuration, getCompetitionToken, toJsonIfOkResponse} from "../../Util";

export const fetchPresence = createAsyncThunk<any, any, any>(
    'player/getPresence',
    async _ => {
        return await fetch(`${configuration.baseUrl}/v2/player?competitionToken=${getCompetitionToken()}`, {
            method: "GET"
        }).then(toJsonIfOkResponse)
    }
);

export const postPresence = createAsyncThunk<any, any[], any>(
    'player/setPresence',
    async idAndPresence => {
        return await fetch(`${configuration.baseUrl}/v2/player`, {
            method: "POST",
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: `competitionToken=${getCompetitionToken()}&playerId=${idAndPresence[0]}${idAndPresence.length > 1 ? '&presence=' + idAndPresence[1] : ''}`
        }).then(toJsonIfOkResponse)
    }
);

export const createGuest = createAsyncThunk<any, any[], any>(
    'player/updateGuest',
    async nameAndRating => {
        return await fetch(`${configuration.baseUrl}/v2/player/createGuest`, {
            method: "POST",
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: `competitionToken=${getCompetitionToken()}&name=${nameAndRating[0]}&startRating=${nameAndRating[1]}`
        }).then(toJsonIfOkResponse)
    }
);

const initialState: Player[] = []

export const playerPresenceSlice = createSlice({
        name: 'playerPresence',
        initialState: initialState,
        reducers: {},
        extraReducers: (builder) => {
            builder.addCase(fetchPresence.fulfilled, (state: Player[], action) => {
                updateState(state, action);
            }).addCase(postPresence.fulfilled, (state: Player[], action) => {
                updateState(state, action);
            }).addCase(createGuest.fulfilled, (state: Player[], action) => {
                updateState(state, action);
            })
        }
    }
)

function updateState(state: Player[], action: { payload: Player[]; }){
    state.splice(0,state.length);
    state.push(...action.payload.sort((m1, m2) => m1.name.localeCompare(m2.name)));
}

export const playerPresence = (state: {playerPresence: Player[]}) => state.playerPresence;
