import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import HighlightIcon from "@mui/icons-material/Highlight";

function Trophy({name}: {name: string}){
    if (name.startsWith("Koning")){
        return <EmojiEventsIcon style={{marginBottom: -6, color: "gold"}}/>
    }
    if (name.startsWith("Hertog")){
        return <EmojiEventsIcon style={{marginBottom: -6, color: "silver"}}/>
    }
    if (name.startsWith("Graaf")){
        return <EmojiEventsIcon style={{marginBottom: -6, color: "burlywood"}}/>
    }
    if (name.startsWith("Leerling")){
        return <HighlightIcon style={{marginBottom: -6, color: "red", transform: "rotate(180deg)"}}/>
    }
    return null;
}
export default Trophy;
