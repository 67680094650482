import {createSlice} from '@reduxjs/toolkit';
import {Auth} from "../../models/auth";

const initialState: {authObject: Auth} = {
    authObject: {
        isAuthenticated: false
    }
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthenticated: (state, action : {payload: Auth, type: string}) => {
            state.authObject = action.payload;
        }
    },
});

export const { setAuthenticated } = authSlice.actions;

export const isAuthenticated = (state: {auth: {authObject: Auth}}) => state.auth.authObject.isAuthenticated;
