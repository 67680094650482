import React from "react";

function GenerationExplanation(){
    return <div>

        <p>
            Het bepalen van een wedstrijdprogramma gebeurt door verschillende wedstrijdprogramma's te genereren en daar de beste uit te kiezen. Die worden vergeleken door:
        </p>
        <ul>
            <li>gelijkwaardigheid koppels tegen elkaar op basis van rating</li>
            <li>hoe recent hebben de medespelers met elkaar gespeeld</li>
            <li>hoe recent hebben de tegenstanders tegen elkaar gespeeld</li>
        </ul>
    </div>
}

export default GenerationExplanation;
