import {Card} from "@mui/material";
import RankingExplanation from "./rankingExplanation";
import React from "react";
import GenerationExplanation from "./generationExplanation";
import RatingExplanation from "./ratingExplanation";

function NoToken() {
    const pstyle = {marginTop: 0, marginBottom: 0};
    const h3Style = {marginBottom: '0.2em'};
    return <div>
        <h2>Over de toss applicatie</h2>
        <p>
            Deze toss applicatie genereert wedstrijden 2 tegen 2 (tennis of padel dubbel) voor de mensen die op dat
            moment mee willen doen. Op basis
            van de resultaten leert het systeem hoe goed de spelers zijn. Deze kennis wordt gebruikt om een passend
            programma te berekenen.
        </p>
        <p>
            Om deze app te gebruiken heb je een unieke link voor jouw competitie nodig. Deze link krijg je van de
            persoon die de competitie beheert. Je kunt ook zelf die persoon zijn, door onder 'Mijn competities' een
            competitie aan te maken.
        </p>
        <h2>Hoe werkt dat?</h2>
        <h3 style={h3Style}>Aanwezigheid</h3>
        <p style={pstyle}>
            Via de unieke link kun je aangeven of je aanwezig bent:
        </p>
        <Card sx={{maxWidth: "sm"}}>
            <img style={{width: '100%'}} src="/images/presence.png" alt="Aanwezigheid"/>
        </Card>

        <h3 style={h3Style}>Programma</h3>
        <p style={pstyle}>
            Als de aanwezigheid bekend is kunnen de wedstrijden bepaald worden:
        </p>
        <Card sx={{maxWidth: "sm"}}>
            <img style={{width: '100%'}} src="/images/draw.png" alt="Wedstrijden genereren"/>
        </Card>

        <GenerationExplanation/>
        <h3 style={h3Style}>Uitslagen</h3>
        <p style={pstyle}>
            Via dit schema kan ook de uitslag ingevoerd worden:
        </p>
        <Card sx={{maxWidth: "sm"}}>
            <img style={{width: '100%'}} src="/images/results.png" alt="Uitslagen"/>
        </Card>

        <h3 style={h3Style}>Stand</h3>
        <p style={pstyle}>
            Deze uitslagen leiden tot ranglijsten per maand, per jaar en allertijden. De maandwinnaar mag zich de hele
            volgende maand koning noemen. In dit voorbeeld is het november en zijn de titels gebaseerd op de eindstand
            van oktober:
        </p>
        <Card sx={{maxWidth: "sm"}}>
            <img style={{width: '100%'}} src="/images/rank.png" alt="Ranglijst"/>
        </Card>
        <RankingExplanation/>

        <h3 style={h3Style}>Rating</h3>
        <p style={pstyle}>
            De uitslagen zorgen ook dat de rating van een speler aangepast wordt. De rating wordt gebruikt om
            gelijkwaardige wedstrijden te genereren:
        </p>
        <Card sx={{maxWidth: "sm"}}>
            <img style={{width: '100%'}} src="/images/rating.png" alt="Rating"/>
        </Card>
        <RatingExplanation />

        <h3 style={h3Style}>Rating uitleg</h3>
        <p style={pstyle}>
            Door op een speler in de ratingtabel te klikken wordt getoond hoe deze rating tot stand is gekomen:
        </p>
        <Card sx={{maxWidth: "sm"}}>
            <img style={{width: '100%'}} src="/images/ratingExplanantion.png" alt="Totstandkoming rating"/>
        </Card>

        <h2>Contact</h2>
        <p>
            Je kunt contact opnemen via <a href="mailto:toss@easingyou.com">toss@easingyou.com</a>
        </p>
        <p>
            Toss is een applicatie van EasingYou:<br /><strong>KvK:</strong> 54309921<br/><strong>BTW:</strong> NL001918505B13
        </p>

    </div>
}

export default NoToken;
