import {Alert, Button, ButtonGroup} from "@mui/material";
import {draw, loadedGames, undraw} from "./gameSlice";
import {pending} from "../general/connectionSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import React from "react";
import ScoreButton from "./ScoreButton";

function GameSection(){
    const dispatch = useAppDispatch();
    const games = useAppSelector(loadedGames);
    const isPending = useAppSelector(pending);
    const hasUnfinishedGames = games.games.filter(game => game.homePoints === null || game.awayPoints === null).length > 0;

    const removeUnfinishedGamesSection = hasUnfinishedGames ? <div style={{marginBottom: '1em'}}>
        <Button fullWidth variant="contained" color="error" disabled={isPending} onClick={() => dispatch(undraw([]))}>
            Verwijder ongespeelde wedstrijden
        </Button>
    </div> : null;

    return <div>
        <div style={{marginTop: '1em', marginBottom: '1em'}}>
            <Button fullWidth variant="contained" color="success" disabled={isPending} onClick={() => dispatch(draw([]))}>
                Genereer wedstrijden
            </Button>
        </div>
        {removeUnfinishedGamesSection}
        <Alert icon={false} severity={"info"}>
            <ul>
                <li>Klik op de atleten om een set toe te voegen</li>
                <li>Klik op het aantal sets (getal) om een set te verwijderen</li>
            </ul>
        </Alert>
        {games.games.map((game, index) =>
            <div key={game.id} style={{marginBottom: '0.5em'}}>
                {index === 0 || getDateString(games.games[index].startsAt) !== getDateString(games.games[index-1].startsAt) ? <h3 style={{marginBottom: 0}}>{getDateString(game.startsAt)}</h3> : null}

                <ButtonGroup disabled={isPending} fullWidth variant="outlined" aria-label="outlined button group">
                    <ScoreButton game={game} isHome={true}/>
                    <ScoreButton game={game} isHome={false}/>
                </ButtonGroup>
            </div>
        )
        }
    </div>
}

function getDateString(datetime: string): string{
    return new Date(datetime).toLocaleDateString()
}

export default GameSection;
