import React from "react";

function RatingExplanation(){
    return <div>
        <p>De rating is gebaseerd op het Elo rating systeem, bedacht door Arpad Elo. De enige aanpassing is dat er
            rekening gehouden wordt met de uitslag, waarbij het Elo systeem alleen winst / gelijk / verlies
            onderscheidt. Voor de rating geldt:</p>
        <ul>
            <li>Hoe beter de speler, hoe hoger het getal</li>
            <li>De beheerder van de competitie bepaalt de rating waar een speler mee start</li>
            <li>Een winstpartij waarin alle punten gepakt worden (b.v. 3-0) tegen een even sterke tegenstander levert 32 punten op
            </li>
            <li>Het aantal punten wordt beïnvloed door de uitslag en de ratingverhouding tussen de tegenstanders</li>
        </ul>
    </div>
}

export default RatingExplanation;
