import {ConnectionState, PossibleConnectionState} from "../../models/connectionState";
import {createSlice, isFulfilled, isPending, isRejected, isRejectedWithValue} from "@reduxjs/toolkit";

const initialState: ConnectionState = {};

export const connectionSlice = createSlice({
        name: 'connection',
        initialState: initialState,
        reducers: {},
        extraReducers: (builder) => {
            builder.addMatcher(isPending,(state: ConnectionState, action) => {
                state.state = PossibleConnectionState.PENDING;
            }).addMatcher(isFulfilled,(state: ConnectionState, action) => {
                state.state = PossibleConnectionState.FULFILLED;
            }).addMatcher(isRejected,(state: ConnectionState, action) => {
                state.message = action.error?.message;
                state.state = PossibleConnectionState.REJECTED;
            }).addMatcher(isRejectedWithValue,(state: ConnectionState, action) => {
                state.state = PossibleConnectionState.REJECTED;
                state.message = action.error?.message;
            })
        }
    }
)

export const connectionState = (state: {connection: ConnectionState}) => state.connection;
export const pending = (state: {connection: ConnectionState}) => state.connection.state === PossibleConnectionState.PENDING;
