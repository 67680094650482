import {connectionState} from "./connectionSlice";
import {PossibleConnectionState} from "../../models/connectionState";
import {Alert, CircularProgress} from "@mui/material";
import {useAppSelector} from "../../app/hooks";

function ConnectionIndicator(){
    const connState = useAppSelector(connectionState);
    if (connState.state === PossibleConnectionState.PENDING){
        return <div style={{position: "fixed", zIndex: 100, marginTop: '1em', marginLeft: '1em'}}><CircularProgress /></div>;
    }

    if (connState.state === PossibleConnectionState.REJECTED){
        let text = "Er ging ff iets niet helemaal lekker";
        if (connState.message){
            text += " (" + connState.message + ")";
        }
        return <div>
                <Alert style={{position: "fixed", zIndex: 100}} severity="error">{text}</Alert>
                <Alert style={{visibility: "hidden"}} severity="error">{text}</Alert>
        </div>;
    }

    return <div></div>;
}


export default ConnectionIndicator;
