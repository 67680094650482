import React from "react";

function RatingDiffCell(props: { lastChange: number }) {
    const diff = Math.round(100 * props.lastChange) / 100.0;
    let textColor;
    if (diff > 0) {
        textColor = {color: "green"}
    } else if (diff < 0) {
        textColor = {color: "red"}
    } else {
        textColor = {};
    }
    return <span style={textColor}>{diff}</span>
}

export default RatingDiffCell;
