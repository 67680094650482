import {Button, ButtonGroup, List, ListItem, ListItemText} from "@mui/material";
import React from "react";
import {postPresence} from "./playerPresenceSlice";
import {pending} from "../general/connectionSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {Player} from "../../models/players";

interface PlayerTableProps {
    players: Player[];
}

function PlayerTable(props: PlayerTableProps){
    const { players } = props;
    const dispatch = useAppDispatch();
    const isPending = useAppSelector(pending);
    return <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {players.map(player =>
        <ListItem key={player.id}>
            <ListItemText id="name" primary={player.name} />
            <ButtonGroup disabled={isPending} variant="outlined" aria-label="outlined button group">
                <Button variant={player.present === true ? "contained" : "outlined"} color="success" onClick={() => dispatch(postPresence(player.present === true ? [player.id] : [player.id, true]))}>Aan</Button>
                <Button variant={player.present === false ? "contained" : "outlined"} color="error" onClick={() => dispatch(postPresence(player.present === false ? [player.id] : [player.id, false]))}>Uit</Button>
            </ButtonGroup>
        </ListItem>)}
    </List>;
}

export default PlayerTable;
