export interface Rating {
    gameRatings: GameRatingRow[],
    playerRatings: PlayerRatingRow[]
}

export interface PlayerRatingRow {
    playerId: number,
    playerName: string,
    currentRating: number,
    lastChange: number
}
export interface GameRatingRow {
    gameId: number,
    playerId: number,
    playerName: string,
    homePlayer: boolean,
    result: GameResult,
    newRating: number,
    expectedScore: number,
    score: number | null
    earnedRating: number | null,
    startsAt: string
}

export enum GameResult {
    HOME_WIN = 'HOME_WIN',
    DRAW = 'DRAW',
    AWAY_WIN = 'AWAY_WIN'
}
