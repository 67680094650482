import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import {playerPresenceSlice} from "../features/player/playerPresenceSlice";
import {gameSlice} from "../features/game/gameSlice";
import {connectionSlice} from "../features/general/connectionSlice";
import {rankSlice} from "../features/rank/rankSlice";
import {ratingSlice} from "../features/rating/ratingSlice";
import {authSlice} from "../features/auth/authSlice";
import {competitionSlice} from "../features/competition/competitionSlice";
import {donationSlice} from "../features/donation/donationSlice";

export const store = configureStore({
  reducer: {
    playerPresence: playerPresenceSlice.reducer,
    game: gameSlice.reducer,
    connection: connectionSlice.reducer,
    rank: rankSlice.reducer,
    rating: ratingSlice.reducer,
    auth: authSlice.reducer,
    competition: competitionSlice.reducer,
    donation: donationSlice.reducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
