import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Game, Games} from "../../models/game";
import {configuration, getCompetitionToken, toJsonIfOkResponse} from "../../Util";

export const getRecentGames = createAsyncThunk<any, any, any>(
    'game/getRecentGames',
    async _ => {
        return await fetch(`${configuration.baseUrl}/game?competitionToken=${getCompetitionToken()}`, {
            method: "GET"
        }).then(toJsonIfOkResponse)
    }
);

export const draw = createAsyncThunk<any, any[], any>(
    'game/draw',
    async _ => {
        return await fetch(`${configuration.baseUrl}/game/draw`, {
            method: "POST",
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: `competitionToken=${getCompetitionToken()}`
        }).then(toJsonIfOkResponse)
    }
);

export const undraw = createAsyncThunk<any, any[], any>(
    'game/undraw',
    async _ => {
        return await fetch(`${configuration.baseUrl}/game/undraw`, {
            method: "POST",
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: `competitionToken=${getCompetitionToken()}`
        }).then(toJsonIfOkResponse)
    }
);

export const postResult = createAsyncThunk<any, any[], any>(
    'game/result',
    async gameIdAndResult => {
        return await fetch(`${configuration.baseUrl}/game/result`, {
            method: "POST",
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: `competitionToken=${getCompetitionToken()}&gameId=${gameIdAndResult[0]}&intervalCode=${gameIdAndResult[1]}&isHome=${gameIdAndResult[2]}&deltaPoints=${gameIdAndResult[3]}`
        }).then(toJsonIfOkResponse)
    }
);

const initialState: Games = {
    games: []
};

export const gameSlice = createSlice({
        name: 'game',
        initialState: initialState,
        reducers: {},
        extraReducers: (builder) => {
            builder.addCase(draw.fulfilled, (state: Games, action) => {
                setState(state, action.payload);
            }).addCase(undraw.fulfilled, (state: Games, action) => {
                setState(state, action.payload);
            }).addCase(getRecentGames.fulfilled, (state: Games, action) => {
                setState(state, action.payload);
            }).addCase(postResult.fulfilled, (state: Games, action: {payload: {games: Game[]}}) => {
                setState(state, action.payload.games);
            })
        }
    }
)

function setState(state: Games, games: Game[]){
    state.games = games.sort((g1, g2) => g2.id - g1.id);
}

export const loadedGames = (state: {game: Games}) => state.game;
