import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {configuration, getCompetitionToken, toJsonIfOkResponse, toTextIfOkResponse} from "../../Util";
import {Donation} from "../../models/donation";

export const postDonation = createAsyncThunk<any, any[], any>(
    'donation/post',
    async (donation, { rejectWithValue }) => {
       return await fetch(`${configuration.baseUrl}/donation`, {
                method: "POST",
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                body: `competitionToken=${getCompetitionToken()}&playerId=${donation[0]}&cents=${donation[1]}`
            })
           .then(toTextIfOkResponse)
    }
);

export const getDonationInfo = createAsyncThunk<any, any[], any>(
    'donation/get',
    async _ => {
        return await fetch(`${configuration.baseUrl}/donation?competitionToken=${getCompetitionToken()}`, {
            method: "GET"
        }).then(toJsonIfOkResponse)
    }
);

function isDonationRedirect(){
    return new URL(window.location.href).searchParams.get("donationRedirect") === 'true';
}

const initialState: Donation = {
    justReturnedFromDonation: isDonationRedirect()
};

export const donationSlice = createSlice({
        name: 'donation',
        initialState: initialState,
        reducers: {
            resetJustReturnedFromDonation: (state: Donation) => {
                state.justReturnedFromDonation = false;
            }
        },
        extraReducers: (builder) => {
            builder.addCase(postDonation.fulfilled, (state: Donation, action) => {
                state.donationLink = action.payload;
            }).addCase(getDonationInfo.fulfilled, (state: Donation, action) => {
                state.nOfDonations = action.payload.nOfDonations;
            })
        }
    }
)

export const {resetJustReturnedFromDonation}  = donationSlice.actions;

export const getDonation = (state: {donation: Donation}) => state.donation;
