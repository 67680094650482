import React, {useEffect} from 'react';
import PlayerPresence from "./features/player/playerPresence";
import {Alert, Button, ButtonGroup, Container} from "@mui/material";
import GameSection from "./features/game/gameSection";
import {getCompetitionToken} from "./Util";
import ConnectionIndicator from "./features/general/ConnectionIndicator";
import RankSection from "./features/rank/rankSection";
import {useAppDispatch, useAppSelector} from "./app/hooks";
import {fetchPresence} from "./features/player/playerPresenceSlice";
import {getRecentGames} from "./features/game/gameSlice";
import {fetchRank, ranking} from "./features/rank/rankSlice";
import {Tabs} from "./models/tabs";
import RatingSection from "./features/rating/ratingSection";
import NoToken from "./features/general/noToken";
import ErrorBoundary from "./ErrorBoundary";
import CompetitionSection from "./features/competition/competitionSection";
import RankingExplanation from "./features/general/rankingExplanation";
import GenerationExplanation from "./features/general/generationExplanation";
import DonateSection from "./features/donation/donateSection";
import {getDonation, resetJustReturnedFromDonation} from "./features/donation/donationSlice";
import DonationOverview from "./features/donation/donationOverview";

function loadData(dispatch: any, month: number){
    dispatch(fetchPresence([]));
    dispatch(getRecentGames([]));
    dispatch(fetchRank(month));
}

function isStandalone(nav: any){
    return nav.standalone === true || window.matchMedia('(display-mode: standalone)').matches;
}

function App() {
    const dispatch = useAppDispatch();
    const competitionToken = getCompetitionToken();
    const month = useAppSelector(ranking).month;
    const donation = useAppSelector(getDonation);
    if (donation.donationLink){
        window.location.href = donation.donationLink;
    }

    const [selectedTab, setSelectedTab] = React.useState(donation.justReturnedFromDonation ? Tabs.More : Tabs.Overzicht);
    useEffect(() => {
        if (competitionToken){
            loadData(dispatch, month);
        }
    }, [dispatch, month, competitionToken]);

    let content;
    let fallback;

    if (competitionToken){
        const refreshButton = isStandalone(window.navigator) ? <Button style={{position: 'sticky', top: 0, zIndex: 50, marginBottom: 10}} fullWidth variant="contained" color="success" onClick={() => window.location.reload()}>Ververs pagina</Button> : null;
        fallback = <Container maxWidth="sm">{refreshButton}<Alert severity="error">Er ging iets niet helemaal goed. {refreshButton ? "Probeer de pagina te verversen" : "Probeer de pagina te herladen."}</Alert></Container>
        content = <div>
            {refreshButton}
            <ButtonGroup variant="text" fullWidth={true} aria-label="text button group">
                {[Tabs.Overzicht, Tabs.Rating, Tabs.More].map(tab => <Button key={tab} onClick={() => {setSelectedTab(tab);dispatch(resetJustReturnedFromDonation())}} variant={selectedTab === tab ? "contained" : "text"}>{tab}</Button>)}
            </ButtonGroup>
            {getTab(selectedTab)}
        </div>;
    } else {
        fallback = <Container maxWidth="sm"><Alert severity="error">Er ging iets niet helemaal goed. Probeer de pagina te herladen.</Alert></Container>
        content = <div>
            <CompetitionSection />
            <NoToken />
        </div>
    }

    return (
        <ErrorBoundary fallback={fallback}>
            <Container maxWidth="sm" style={{marginBottom: '1em'}}>
                <ConnectionIndicator />
                {content}
            </Container>
        </ErrorBoundary>
  );
}

function getTab(selectedTab:Tabs){

    if (selectedTab === Tabs.Overzicht) {
        return <div>
            <DonationOverview />
            <RankSection/>
            <PlayerPresence/>
            <GameSection/>
        </div>
    }

    if (selectedTab === Tabs.Rating){
        return <div>
            <RatingSection />
        </div>;
    }
    if (selectedTab === Tabs.More){
        return <div>
            <h2>Fooienpot</h2>
            <DonateSection/>
            <h2>De ranglijst</h2>
            <RankingExplanation/>
            <h2>Het genereren van wedstrijden</h2>
            <GenerationExplanation/>
            <h2>Contact</h2>
            <p>
                Je kunt contact opnemen via <a href="mailto:toss@easingyou.com">toss@easingyou.com</a>
            </p>
        </div>;
    }
}

export default App;
