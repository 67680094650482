import {Rank, RankRow} from "../../models/rank";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {configuration, getCompetitionToken, toJsonIfOkResponse} from "../../Util";
import {postResult} from "../game/gameSlice";

export const fetchRank = createAsyncThunk<any, any, any>(
    'rank',
    async month => {
        return await fetch(`${configuration.baseUrl}/v2/rank?competitionToken=${getCompetitionToken()}&intervalCode=${month}`, {
            method: "GET"
        }).then(toJsonIfOkResponse)
    }
);

const initialState: Rank = {
    rows: [],
    month: new Date().getMonth() + 1
};

export const rankSlice = createSlice({
        name: 'rank',
        initialState: initialState,
        reducers: {
            selectMonth: (state: Rank, selectedMonth: PayloadAction<number>) => {
                state.month = selectedMonth.payload;
            }
        },
        extraReducers: (builder) => {
            builder.addCase(fetchRank.fulfilled, (state: Rank, action: { payload: RankRow[][]; }) => {
                state.rows = action.payload;
            }).addCase(postResult.fulfilled, (state: Rank, action: {payload: {rank: RankRow[][]}}) => {
                state.rows = action.payload.rank;
            })
        }
    }
)
export const {selectMonth} = rankSlice.actions;
export const ranking = (state: {rank: Rank}) => state.rank;
