import React, {CSSProperties, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    FormControl,
    Grid,
    Link,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {isAuthenticated} from "../auth/authSlice";
import keycloak from "../../app/keycloak";
import Button from "@mui/material/Button";
import {
    createCompetition,
    getCompetitionPlayers,
    getCompetitions,
    resetPresence,
    updatePlayer
} from "./competitionSlice";
import {pending} from "../general/connectionSlice";
import {Competition, CompetitionState} from "../../models/competitionState";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CreatePlayerRow from "./CreatePlayerRow";
import {Player} from "../../models/players";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {AnyAction, Dispatch, ThunkDispatch} from "@reduxjs/toolkit";
import {Auth} from "../../models/auth";
import {ConnectionState} from "../../models/connectionState";
import {Games} from "../../models/game";
import {Rank} from "../../models/rank";
import {Rating} from "../../models/rating";

function CompetitionSection() {
    const dispatch = useAppDispatch();
    const isAuth = useAppSelector(isAuthenticated);
    const competitions = useAppSelector(getCompetitions);
    const [name, setName] = React.useState('');
    const isPending = useAppSelector(pending);
    const title = <h2>Mijn competities</h2>;

    useEffect(() => {
        if (isAuth) {
            dispatch(getCompetitionPlayers())
        }
    }, [dispatch, isAuth]);

    if (!isAuth) {
        return <div>
            {title}
            <p>Om competities te kunnen maken en te beheren moet je ingelogd zijn. Dat kan via <Link
                onClick={() => keycloak.login()}>auth.easingyou.com</Link></p>
        </div>
    }

    const divStyle: CSSProperties = {marginBottom: '1em'};
    if (competitions.length > 0) {
        divStyle.marginTop = '1em';
    }
    return <div>
        <Grid container spacing={1}>
            <Grid item xs={8}>
                {title}
            </Grid>
            <Grid item xs={4}>
                <Button style={{marginBlockStart: '0.83em'}} fullWidth variant="outlined" color="error"
                        onClick={() => keycloak.logout()}>Uitloggen</Button>
            </Grid>
        </Grid>
        {competitions.map(comp => renderCompetition(comp, isPending, dispatch))}
        <div style={divStyle}>
            <Alert icon={false} severity="info">Kies een naam om een nieuwe competitie aan te maken</Alert>
            <Grid container spacing={1}>
                <Grid item xs={9}>
                    <TextField size="small" style={{width: '100%'}} id="competitionName" label="Naam" variant="outlined"
                               value={name}
                               disabled={isPending}
                               onChange={(val: any) => setName(val.target.value)}/>
                </Grid>
                <Grid item xs={3}>
                    <Button style={{width: '100%'}} variant="contained" color="success" disabled={isPending || !name}
                            onClick={() => {
                                dispatch(createCompetition(name));
                                setName("")
                            }}><AddCircleOutlineIcon/></Button>
                </Grid>
            </Grid>
        </div>
    </div>
}

function renderCompetition(competition: Competition, isPending: boolean, dispatch: ThunkDispatch<{ playerPresence: Player[]; game: Games; connection: ConnectionState; rank: Rank; rating: Rating; auth: { authObject: Auth; }; competition: CompetitionState; }, undefined, AnyAction> & Dispatch<AnyAction>) {
    return <Accordion key={competition.competitionToken}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography style={{fontWeight: 'bold'}}>{competition.competitionName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Button fullWidth variant="contained" color="error" disabled={isPending} onClick={() => dispatch(resetPresence(competition.competitionToken))}>Reset aanwezigheid</Button>
            <p>
                <strong>Link: </strong><a href={`https://toss.easingyou.com/?token=${competition.competitionToken}`}>https://toss.easingyou.com/?token={competition.competitionToken}</a>
            </p>
            <strong>Spelers</strong>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {competition.players.map(player =>
                    <ListItem key={player.playerId}>
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <ListItemText id="name" primary={player.playerName} />
                            </Grid>
                            <Grid item xs={5}>
                                <FormControl disabled={isPending} fullWidth>
                                <Select
                                    size="small"
                                    id="player-type-update"
                                    value={player.playerType}
                                    label=""
                                    onChange={(event:any) => {dispatch(updatePlayer([player.playerId, event.target.value]))}}
                                >
                                    <MenuItem value='Member'>Lid</MenuItem>
                                    <MenuItem value='Guest'>Invaller</MenuItem>
                                    <MenuItem value='ArchivedPlayer'>Archief</MenuItem>
                                </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <ListItemText id="rating" primary={player.playerRating} />
                            </Grid>
                        </Grid>
                    </ListItem>)}
            </List>
            <CreatePlayerRow competition={competition}/>
        </AccordionDetails>
    </Accordion>
}


export default CompetitionSection;
