import React from "react";

function RankingExplanation(){
    return <div>
        <p>
            De ranglijst wordt als volgt bepaald:
        </p>
        <ul>
            <li>eerst de meeste wedstrijdpunten (winst = 2 punten, gelijk = 1 punt)</li>
            <li>dan de meeste gewonnen sets</li>
            <li>dan de hoogste weerstand (beste tegenstanders gehad)</li>
            <li>dan de meeste wedstrijden (vaker komen wordt beloond)</li>
        </ul>
        <p>
            Als alles gelijk is delen spelers een positie.
        </p>
    </div>
}

export default RankingExplanation;
