import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {playerPresence} from "../player/playerPresenceSlice";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {getDonation, getDonationInfo} from "./donationSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function DonateSection(){
    const dispatch = useAppDispatch();
    const players = useAppSelector(playerPresence);
    const donation = useAppSelector(getDonation);
    useEffect(() => {
        dispatch(getDonationInfo([]));
    }, [dispatch]);

    let formattedNoOfDonations;
    let nOfDonationsMessage;
    if (donation.nOfDonations === undefined){
        formattedNoOfDonations = '';
        nOfDonationsMessage = '';
    } else {
        formattedNoOfDonations = ' (' + donation.nOfDonations + ")";
        const howManyTimes = donation.nOfDonations === 0 ? "niet" : donation.nOfDonations + " keer";
        nOfDonationsMessage = "De fooienpot heeft de afgelopen 30 dagen " + howManyTimes + " gerinkeld.";
    }
    const generousTitle = "de Gulle";
    const generousPlayers = players.filter(player => player.name.endsWith(generousTitle));

    let genPlayer;
    if (generousPlayers.length === 0){
        genPlayer = null;
    } else {
        const name = generousPlayers[0].name;
        let nameWithoutGenerous = name.substring(0, name.length - generousTitle.length);
        genPlayer = <div>
            <p>In deze periode heeft {nameWithoutGenerous} het meest ruimhartig gebruik gemaakt van de fooienpot. Daarmee heeft deze weldoender de titel 'de Gulle' meer dan verdiend.</p>
        </div>
    }

    return <div style={{marginTop: "1em", marginBottom: "0.5em"}}>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography style={{fontWeight: 'bold'}}>Fooienpot{formattedNoOfDonations}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <span>{nOfDonationsMessage}</span>
                {genPlayer}

                <p>Er kan gedoneerd worden onder het kopje <strong>Meer</strong></p>
            </AccordionDetails>
        </Accordion>

    </div>
}

export default DonateSection;
