import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {playerPresence} from "../player/playerPresenceSlice";
import {
    Alert,
    AlertTitle,
    Button,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select
} from "@mui/material";
import React, {CSSProperties} from "react";
import {pending} from "../general/connectionSlice";
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import {getDonation, postDonation} from "./donationSlice";

function DonateSection(){
    const dispatch = useAppDispatch();
    const players = useAppSelector(playerPresence);
    const isPending = useAppSelector(pending);
    const donation = useAppSelector(getDonation);
    const [player, setPlayer] = React.useState(-1);
    const [amount, setAmount] = React.useState<number>(5.00)

    const warning = amount < 3 ? <Alert severity={"warning"}>Er kan vanaf 3 euro gedoneerd worden.</Alert> : null;
    const thankYouOrInfo = donation.justReturnedFromDonation ? <Alert severity="success">
        <AlertTitle>Bedankt voor je donatie!</AlertTitle>
        Jouw bijdrage helpt enorm. Veel plezier met het verdere gebruik van de toss applicatie.
    </Alert> : <Alert icon={false} severity={"info"}>De toss applicatie is gratis. Als je toch iets bij wil dragen kun je deze fooienpot gebruiken.</Alert>;


    const margin: CSSProperties = warning || thankYouOrInfo ? {marginTop: '1em'} : {};
    return <div>
        {thankYouOrInfo}
        {warning}
        <Grid style={margin} container spacing={1}>
            <Grid item xs={7}>
                <FormControl disabled={isPending} fullWidth>
                    <InputLabel id="player-label">Atleet</InputLabel>
                    <Select
                        labelId="player-label"
                        size="small"
                        id="player"
                        value={player}
                        label="Type"
                        onChange={(event:any) => {setPlayer(event.target.value)}}
                    >
                        <MenuItem value='-1'></MenuItem>
                        {players.map(player => <MenuItem key={player.id} value={player.id}>{player.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={5}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">Bedrag</InputLabel>
                    <OutlinedInput
                        size="small"
                        type="number"
                        aria-valuemin={5}
                        value={amount}
                        id="outlined-adornment-amount"
                        startAdornment={<InputAdornment position="start">€</InputAdornment>}
                        label="Bedrag"
                        disabled={isPending}
                        onChange={(val: any) => setAmount(val.target.value)}
                    />
                </FormControl>

            </Grid>
            <Grid item xs={12}>
                <Button fullWidth variant="contained" color="success" disabled={isPending || amount < 3 || player < 1} onClick={() => {dispatch(postDonation([player, Math.round(100 * amount)]));}}><VolunteerActivismOutlinedIcon /></Button>
            </Grid>
        </Grid>

    </div>
}

export default DonateSection;
