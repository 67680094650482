import {Alert, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import React from "react";
import {pending} from "../general/connectionSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {Competition} from "../../models/competitionState";
import {addPlayer} from "./competitionSlice";

function CreatePlayerRow(props: {competition: Competition}){
    const dispatch = useAppDispatch();
    const competition = props.competition;
    const players = competition.players;
    const isPending = useAppSelector(pending);
    const [name, setName] = React.useState('');
    const [type, setType] = React.useState('Member');
    const [startRating, setStartRating] = React.useState<number | ''>('');
    const forbiddenNames = ['Koning','Hertog','Graaf','Leerling'];
    const nameInUse = players.map(player => player.playerName.replace(new RegExp(forbiddenNames.join("|")), "").trim()).includes(name);
    let warningText = null;
    if (nameInUse){
        warningText = "Er bestaat al een speler met de naam " + name;
    } else if (forbiddenNames.map(nm => nm.toLowerCase()).find(forbidden => name.toLowerCase().startsWith(forbidden))){
        warningText = "Een naam mag niet beginnen met 'Koning', 'Hertog', 'Graaf' of 'Leerling'";
    } else if (startRating !== '' && (startRating < 1000 || startRating > 2000)){
        warningText = "Startrating moet tussen de 1000 en 2000 liggen";
    }
    const warning = warningText ? <Alert severity={"warning"}>{warningText}</Alert> : null;
    return <div>
        <Alert icon={false} severity="info">Je kunt hieronder een speler (lid of invaller) toevoegen aan de competitie.
            <ul>
                <li><strong>Startrating: </strong>Hoe hoger het getal, hoe beter de speler. Moet tussen 1000 en 2000 zijn.</li>
                <li><strong>Type:</strong> Een lid doet mee in de ranglijst, een invaller niet.</li>
            </ul>
        </Alert>
        {warning}
        <Grid container spacing={1}>
        <Grid item xs={12}>
            <TextField fullWidth size="small" style={{width: '100%'}} id="playerName" label="Naam" variant="outlined" value={name}
                       disabled={isPending}
                       onChange={(val: any) => setName(val.target.value)}/>
        </Grid>
        <Grid item xs={5}>
            <TextField fullWidth size="small" id="playerRating" label="Startrating" variant="outlined" value={startRating} type="number" aria-valuemin={1000} aria-valuemax={2000}
                       disabled={isPending}
                       onChange={(val: any) => setStartRating(val.target.value)}/>
        </Grid>
        <Grid item xs={4}>
            <FormControl disabled={isPending} fullWidth>
                <InputLabel id="player-type-label">Type</InputLabel>
                <Select
                    labelId="player-type-label"
                    size="small"
                    id="player-type"
                    value={type}
                    label="Type"
                    onChange={(event:any) => {setType(event.target.value)}}
                >
                    <MenuItem value='Member'>Lid</MenuItem>
                    <MenuItem value='Guest'>Invaller</MenuItem>
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={3}>
            <Button fullWidth variant="contained" color="success" disabled={isPending || warning !== null || !name || !type || !startRating} onClick={() => {dispatch(addPlayer([competition.competitionToken, name, type, startRating]));setName("");setStartRating('');setType('Member')}}><AddCircleOutlineIcon /></Button>
        </Grid>
    </Grid>
    </div>
}

export default CreatePlayerRow;
