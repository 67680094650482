import {
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import {ranking, selectMonth} from "./rankSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {pending} from "../general/connectionSlice";
import Trophy from "./trophy";

function RankSection(){
    const dispatch = useAppDispatch();
    const isPending = useAppSelector(pending);
    const r = useAppSelector(ranking);
    const rows = r.rows;
    let rowNo = 0;
    return rows.length === 0 ? null : <TableContainer component={Paper} style={{marginTop: '0.5em'}}>
        <Table padding={"none"}>
            <TableHead>
                <TableRow>
                    <TableCell padding={"normal"} colSpan={2}>
                        <FormControl disabled={isPending} fullWidth>
                            <InputLabel id="month-select-label">Wanneer</InputLabel>
                            <Select
                                labelId="month-select-label"
                                size="small"
                                id="similar-player"
                                value={r.month}
                                label="Wanneer"
                                onChange={(event:any) => {dispatch(selectMonth(event.target.value))}}
                            >
                                {getIntervalList()}
                            </Select>
                        </FormControl>

                    </TableCell>
                    <TableCell>
                        <Tooltip style={{borderBottom: '1px dotted black'}} enterTouchDelay={0} title={"Aantal gespeelde wedstrijden"}>
                            <span>Gsp.</span>
                        </Tooltip>
                    </TableCell>
                    <TableCell>
                        <Tooltip style={{borderBottom: '1px dotted black'}} leaveTouchDelay={2500} enterTouchDelay={0} title={"Aantal punten: 2 voor winst, 1 voor gelijkspel"}>
                            <span>Pnt.</span>
                        </Tooltip>
                    </TableCell>
                    <TableCell>
                        <Tooltip style={{borderBottom: '1px dotted black'}} leaveTouchDelay={2500} enterTouchDelay={0} title={"Aantal gewonnen sets"}>
                            <span>Sets</span>
                        </Tooltip>
                    </TableCell>
                    <TableCell>
                        <Tooltip style={{borderBottom: '1px dotted black'}} leaveTouchDelay={6500} enterTouchDelay={0} title={"De ondervonden weerstand: groter getal betekent dat er tegen sterkere tegenstanders is gespeeld"}>
                            <span>Wrstnd</span>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((rankRow) => (
                    rankRow.map((playerRow, playerIndex) =>{
                        rowNo++;
                        return <TableRow
                            key={playerRow.name}
                            style={rowNo%2 === 1 ? {backgroundColor: "#ebebff"} : {}}
                        >
                            <TableCell component="th" scope="row">
                                {playerIndex === 0 ? rowNo : ''}
                            </TableCell>
                            <TableCell>{playerRow.name} <Trophy name={playerRow.name}/></TableCell>
                            <TableCell>{playerRow.nofGames}</TableCell>
                            <TableCell>{playerRow.points}</TableCell>
                            <TableCell>{playerRow.goalResult}</TableCell>
                            <TableCell>{playerRow.resistance}</TableCell>
                        </TableRow>
                    }
                    )
                ))}
            </TableBody>
        </Table>
    </TableContainer>
}



function getIntervalList(){
    let list = ['Aller tijden', 'Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli','Augustus', 'September', 'Oktober', 'November','December']
        .map((name, index) => <MenuItem key={index} value={index}>{name}</MenuItem>);
    for (let year = new Date().getFullYear(); year >= 2022 ; year--) {
        list.splice(1,0,<MenuItem key={year} value={year}>{year}</MenuItem>);
    }
    return list;
}

export default RankSection;
