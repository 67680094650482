export interface Player{
    id: number,
    name: string,
    present?: boolean
    type: PlayerType
}

export enum PlayerType {
    Member = 'Member',
    Guest = 'Guest'
}
